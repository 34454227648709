<template>
    <v-container>
        <v-row>
            <v-col class="pt-0">
                <h2 class="primary--text"> Mes contacts </h2>
            </v-col>
        </v-row>

        <!-- Quick search and pagination -->
        <v-row class="pb-4" align="center" no-gutters>
            <v-col cols="12" md="2" class="mb-2 mb-md-0">
                <v-text-field v-model="quickSearch" @keydown.enter="fetchContacts(true)" label="Recherche..." dense hide-details outlined clearable>
                    <template v-slot:append>
                        <v-icon color="grey" @click="fetchContacts(true)"> fas fa-search </v-icon>
                    </template>
                </v-text-field>
            </v-col>

            <v-col cols="12" md="4">
                <v-btn class="mr-4 ml-0 ml-md-4 mb-2 mb-md-0" @click="displayAdvanceSearch = !displayAdvanceSearch" color="primary" outlined small>
                    <v-icon v-if="!displayAdvanceSearch" left x-small> fas fa-plus </v-icon>
                    <v-icon v-if="displayAdvanceSearch" left x-small> fas fa-minus </v-icon>
                    de filtres
                </v-btn>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn small text class="grey lighten-2 mr-4 mb-2 mb-md-0" v-on="on">
                            {{ contactsCount }}
                        </v-btn>
                    </template>
                    Nombre total de programmes
                </v-tooltip>

                <CreateDenonciationDialog redirectOnCreation :initProgramId="this.programs[0]">
                    <template v-slot:activator="{ on: dialog }">
                        <v-tooltip top>
                            <template v-slot:activator="{ on: tooltip }">
                                <v-btn color="primary" class="mb-2 mb-md-0" small v-on="{ ...dialog, ...tooltip }">
                                    <v-icon small left> far fa-address-card </v-icon>
                                    Dénoncer
                                </v-btn>
                            </template>
                            Dénoncer un nouveau contact
                        </v-tooltip>
                    </template>
                </CreateDenonciationDialog>
            </v-col>

            <v-col cols="12" md="6" class="text-right">
                <v-select class="sort-pagination d-inline-flex" v-model="order" label="Trier par" :menu-props="{ bottom: true, offsetY: true }" :items="contactsSortItems" background-color="white" @change="fetchContacts(true)" hide-details outlined dense>
                    <template v-slot:append-outer>
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <v-btn @click="toggleOrder()" icon v-on="on" class="ml-2">
                                    <v-icon v-bind:class="{'fa-rotate-180': by === 'asc'}">
                                        fas {{ by === 'asc' ? 'fa-sort-amount-down-alt': 'fa-sort-amount-down' }}
                                    </v-icon>
                                </v-btn>
                            </template>
                            <template v-if="by === 'asc'">
                                Croissant
                            </template>
                            <template v-if="by === 'desc'">
                                Décroissant
                            </template>
                        </v-tooltip>
                    </template>
                </v-select>

                <v-pagination v-model="page" @input="fetchContacts(true, false)" :length="totalPages" :total-visible="7" class="pagination d-inline-block" color="primary" />
            </v-col>
        </v-row>

        <!-- Advance search -->
        <v-expand-transition>
            <v-card v-show="displayAdvanceSearch" elevation="0">
                <v-sheet color="grey lighten-3" class="pa-4 mb-4">
                    <span class="caption grey--text text--darken-1"> Contact </span>
                    <v-row>
                        <v-col cols="3">
                            <v-text-field v-model="cityFilter" label="Ville" dense hide-details outlined background-color="white" clear-icon="far fa-times-circle" clearable @keyup.enter="fetchContacts(true)" />
                        </v-col>

                        <v-col cols="3">
                            <v-text-field v-model="zipFilter" label="Code postal" dense hide-details outlined background-color="white" clear-icon="far fa-times-circle" clearable @keyup.enter="fetchContacts(true)" />
                        </v-col>

                        <v-col cols="3">
                            <ProgramAutocomplete ref="programAutocomplete" v-model="programsFilter" label="Programmes" :disabled="embeded" searchOnly multiple noDynamicSearch />
                        </v-col>

                    </v-row>

                    <span class="caption grey--text text--darken-1"> Activités </span>
                    <v-row>
                        <v-col cols="3">
                            <ContactActivitySelect v-model="activitiesFilter" label="Activités" multiple />
                        </v-col>

                        <v-col cols="3">
                            <SaleStatusSelect v-model="saleStatusesFilter" label="Statut réservation" multiple />
                        </v-col>

                        <v-col cols="3">
                            <OptionStatusSelect v-model="optionStatusesFilter" label="Statut option" multiple />
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="2">
                            <v-btn color="primary" class="mr-4" dark depressed small @click="fetchContacts(true)">
                                <v-icon left x-small> fas fa-search </v-icon>
                                Rechercher
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-sheet>
            </v-card>
        </v-expand-transition>

        <!-- Contacts list -->
        <v-data-table class="elevation-2" :headers="headers" :items="contacts" item-key="ids" hide-default-footer :items-per-page="-1">
            <!-- Identity -->
            <template v-slot:item.identity="{ item }">
                <router-link :to="`/contacts/${item.id}`" class="no-text-decoration cursor-pointer">
                    <v-chip label small class="mt-1 cursor-pointer" color="contact-background">
                        <v-icon color="contact-foreground" x-small class="mr-1"> fas fa-user </v-icon>

                        {{ item.name }} {{ item.firstname }}
                    </v-chip>
                </router-link>

                <br>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-chip v-on="on" label small class="my-0" color="transparent">
                            <v-icon color="contact-foreground" x-small class="mr-1"> fas fa-calendar </v-icon>
                            <small>
                                {{ item.created | toDatetime(true) }}
                            </small>
                        </v-chip>
                    </template>
                    Premier contact
                </v-tooltip>

                <v-tooltip top v-if="item.modified && item.modified !== item.created">
                    <template v-slot:activator="{ on }">
                        <v-chip v-on="on" label small class="my-0" color="transparent">
                            <v-icon color="contact-foreground" x-small class="mr-1"> fas fa-calendar </v-icon>
                            <small>
                                {{ item.modified | toDatetime(true) }}
                            </small>
                        </v-chip>
                    </template>
                    Dernier contact
                </v-tooltip>

                <br>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-chip v-on="on" label small class="my-0" color="transparent">
                            <v-icon :color="item.email ? 'contact-foreground' : 'grey'" x-small class="mr-1"> fas fa-envelope </v-icon>
                            {{ item.email }}
                        </v-chip>
                    </template>
                    Adresse mail
                </v-tooltip>

                <br>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-chip v-on="on" label small class="my-0" color="transparent">
                            <v-icon :color="item.mobile ? 'contact-foreground' : 'grey'" x-small class="mr-1"> fas fa-mobile-alt </v-icon>
                            {{ item.mobile }}
                        </v-chip>
                    </template>
                    Numéro de téléphone mobile
                </v-tooltip>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-chip v-if="item.phone" v-on="on" label small class="my-0" color="transparent">
                            <v-icon color="contact-foreground" x-small class="mr-1"> fas fa-phone </v-icon>
                            {{ item.phone }}
                        </v-chip>
                    </template>
                    Numéro de téléphone fixe
                </v-tooltip>

            </template>

            <!-- Location -->
            <template v-slot:item.location="{ item }">
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-chip label color="transparent" v-on="on">
                            <v-icon :color="item.address ? 'contact-foreground' : 'grey'" class="mr-4" x-small> fas fa-map-marker-alt </v-icon>
                            {{ item.address }}
                        </v-chip>
                    </template>
                    Adresse
                </v-tooltip>

                <br>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-chip label color="transparent" v-on="on">
                            <v-icon :color="item.city || item.zip || item.country ? 'contact-foreground' : 'grey'" x-small> fas fa-city </v-icon>
                        </v-chip>
                    </template>
                    Localisation
                </v-tooltip>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-chip label class="px-1" color="transparent" v-on="on">
                            {{ item.city }}
                        </v-chip>
                    </template>
                    Ville
                </v-tooltip>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-chip label class="px-1" color="transparent" v-on="on">
                            {{ item.zip }}
                        </v-chip>
                    </template>
                    Code postal
                </v-tooltip>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-chip label class="px-1" color="transparent" v-on="on">
                            {{ item.country }}
                        </v-chip>
                    </template>
                    Pays
                </v-tooltip>
            </template>

            <!-- Programs -->
            <template v-slot:item.programs="{ item }">
                <template v-for="(program, i) of item.programs.slice(0, 2)">
                    <span :key="program.id">
                        {{ program.title }}
                    </span>

                    <br v-if="i < 2" :key="program.id + '-divider'">
                </template>

                <template v-if="item.programs.length > 2">
                    <v-menu open-on-hover offset-y top>
                        <template v-slot:activator="{ on }">
                            <v-chip label x-small v-on="on"> + {{ item.programs.length - 2 }} </v-chip>
                        </template>
                        <v-card>
                            <v-list dense>
                                <v-list-item v-for="program of item.programs.slice(2, item.programs.length)" :key="program.id" dense class="py-0">
                                    <v-list-item-content class="py-0 caption">
                                        {{ program.title }}
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-card>
                    </v-menu>
                </template>
            </template>

            <!-- Activity -->
            <template v-slot:item.activity="{ item }">
                <ContactDenonciationChip v-if="item.activities.denonciation" :contact="item" />

                <br>

                <ContactOptionChip v-if="item.activities.option" :contact="item" />

                <br>

                <ContactSaleChip v-if="item.activities.sale" :contact="item" />
            </template>

            <!-- Actions -->
            <template v-slot:item.actions="{ item }">
                <CreateDenonciationDialog :visitorId="item.id" :initPartnerContactId="item.contactPartnerId" @denonciation-created="fetchContacts(false, true)">
                    <template v-slot:activator="{ on: dialog }">
                        <v-tooltip top>
                            <template v-slot:activator="{ on: tooltip }">
                                <v-btn color="primary" x-small icon v-on="{ ...dialog, ...tooltip }">
                                    <v-icon> far fa-address-card </v-icon>
                                </v-btn>
                            </template>
                            Dénoncer ce contact
                        </v-tooltip>
                    </template>
                </CreateDenonciationDialog>

                <br>

                <template v-if="item.activities.denonciation && item.activities.denonciation.statusdetail === 'validated'">
                    <CreateOptionDialog :initVisitorId="item.id" isOption :initProgramId="programs[0]">
                        <template v-slot:activator="{ on: menu }">
                            <v-tooltip top>
                                <template v-slot:activator="{ on: tooltip }">
                                    <v-btn color="primary" depressed x-small icon v-on="{...menu, ...tooltip}">
                                        <v-icon>
                                            fas fa-clock
                                        </v-icon>
                                    </v-btn>
                                </template>
                                Poser une option
                            </v-tooltip>
                        </template>
                    </CreateOptionDialog>

                    <CreateOptionDialog v-if="getConfig('sales.enabled', false) && !getConfig('sales.sale_must_be_created_from_option', true)" :initVisitorId="item.id" :isOption="false" :initProgramId="programs[0]">
                        <template v-slot:activator="{ on: menu }">
                            <v-tooltip top>
                                <template v-slot:activator="{ on: tooltip }">
                                    <v-btn color="primary" depressed x-small icon v-on="{...menu, ...tooltip}">
                                        <v-icon>
                                            fas fa-shopping-cart
                                        </v-icon>
                                    </v-btn>
                                </template>
                                Enregistrer une réservation
                            </v-tooltip>
                        </template>
                    </CreateOptionDialog>
                </template>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
import ContactSaleChip from '../components/contacts/ContactSaleChip.vue';
import SaleStatusSelect from '../components/widgets/SaleStatusSelect.vue';
import ContactOptionChip from '../components/contacts/ContactOptionChip.vue';
import OptionStatusSelect from '../components/widgets/OptionStatusSelect.vue';
import CreateOptionDialog from '../components/dialogs/CreateOptionDialog.vue';
import ProgramAutocomplete from '../components/widgets/ProgramAutocomplete.vue';
import ContactActivitySelect from '../components/widgets/ContactActivitySelect.vue';
import ContactDenonciationChip from '../components/contacts/ContactDenonciationChip.vue';
import CreateDenonciationDialog from '../components/contacts/CreateDenonciationDialog.vue';

export default {
    name: 'Contacts',

    props: {
        programs: { type: Array, default: () => ([]) },
        embeded: { type: Boolean, default: false }
    },

    components: {
        ContactSaleChip,
        SaleStatusSelect,
        ContactOptionChip,
        CreateOptionDialog,
        OptionStatusSelect,
        ProgramAutocomplete,
        ContactActivitySelect,
        ContactDenonciationChip,
        CreateDenonciationDialog
    },

    data: () => ({
        page: 1,
        totalPages: 1,
        contactsCount: 0,
        displayAdvanceSearch: false,
        advanceSearchDataFetched: false,
        quickSearch: '',
        order: 'modified',
        by: 'desc',

        cityFilter: '',
        zipFilter: '',
        programsFilter: [],
        activitiesFilter: [],
        saleStatusesFilter: [],
        optionStatusesFilter: [],

        contactsSortItems: [
            { text: 'Nom', value: 'name' },
            { text: 'Date de modification', value: 'modified' },
            { text: 'Ville', value: 'city' },
            { text: 'Date activité', value: 'activity.lastdate' }
        ],

        contacts: [],
        headers: [
            {
                text: 'Identité',
                value: 'identity',
                sortable: false
            },
            {
                text: 'Adresse',
                value: 'location',
                sortable: false
            },
            {
                text: 'Programmes',
                value: 'programs',
                sortable: false
            },
            {
                text: 'Dernières activités',
                value: 'activity',
                sortable: false
            },
            {
                text: 'Actions',
                value: 'actions',
                align: 'center',
                sortable: false,
                width: '5%'
            }
        ]
    }),

    methods: {
        buildQuery() {
            const query = {
                page: this.page,
                include: 'activities,programs',
                orderBy: `${this.order.split(',').map((o) => `${o}:${this.by}`).join(',')}`
            };
            if (this.quickSearch) {
                query.quickSearch = this.quickSearch;
            }
            if (Array.isArray(this.programsFilter) && this.programsFilter.length > 0) {
                query.programs = this.programsFilter.join(',');
            }
            if (Array.isArray(this.activitiesFilter) && this.activitiesFilter.length > 0) {
                query.activities = this.activitiesFilter.join(',');
            }
            if (Array.isArray(this.saleStatusesFilter) && this.saleStatusesFilter.length > 0) {
                query.saleStatuses = this.saleStatusesFilter.join(',');
            }
            if (Array.isArray(this.optionStatusesFilter) && this.optionStatusesFilter.length > 0) {
                query.optionStatuses = this.optionStatusesFilter.join(',');
            }
            if (this.cityFilter) {
                query.city = this.cityFilter;
            }
            if (this.zipFilter) {
                query.zip = this.zipFilter;
            }
            return query;
        },

        buildDisplayQuery() {
            const query = {};
            if (this.page !== 1) {
                query.page = this.page;
            }
            if (this.quickSearch) {
                query.quickSearch = this.quickSearch;
            }
            if (Array.isArray(this.programsFilter) && this.programsFilter.length > 0) {
                query.programs = this.programsFilter.join(',');
            }
            if (Array.isArray(this.activitiesFilter) && this.activitiesFilter.length > 0) {
                query.activities = this.activitiesFilter.join(',');
            }
            if (Array.isArray(this.saleStatusesFilter) && this.saleStatusesFilter.length > 0) {
                query.saleStatuses = this.saleStatusesFilter.join(',');
            }
            if (Array.isArray(this.optionStatusesFilter) && this.optionStatusesFilter.length > 0) {
                query.optionStatuses = this.optionStatusesFilter.join(',');
            }
            if (this.cityFilter) {
                query.city = this.cityFilter;
            }
            if (this.zipFilter) {
                query.zip = this.zipFilter;
            }
            if (this.order !== 'modified') {
                query.order = this.order;
                query.by = this.by;
            }
            return query;
        },

        parseQuery(query) {
            this.page = query.page ? parseInt(query.page) : this.page;
            this.quickSearch = query.quickSearch || null;
            this.cityFilter = query.city ? query.city : null;
            this.zipFilter = query.zip ? query.zip : null;
            this.programsFilter = query.programs ? query.programs.split(',').map((p) => parseInt(p)) : [];
            this.activitiesFilter = query.activities ? query.activities.split(',') : [];
            this.saleStatusesFilter = query.saleStatuses ? query.saleStatuses.split(',') : [];
            this.optionStatusesFilter = query.optionStatuses ? query.optionStatuses.split(',') : [];
            this.order = query.order ? query.order : this.order;
            this.by = query.by ? query.by : this.by;
        },

        doPush(query) {
            return (Object.keys(query).length !== Object.keys(this.$route.query).length) || !Object.keys(query).every((key) => query[key] === this.$route.query[key]);
        },

        async fetchContacts(push, resetPage = true) {
            try {
                this.disableNavigation = true;
                this.setLoading(true);

                if (resetPage) {
                    this.page = 1;
                }

                if (!this.embeded && push && this.doPush(this.buildDisplayQuery())) {
                    this.$router.push({ query: this.buildDisplayQuery() });
                }

                const query = this.buildQuery();
                const { contacts, count, totalPages } = await this.repos.contacts.getContacts(query);
                this.contacts = contacts;
                this.contactsCount = count;
                this.totalPages = totalPages;
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors du rechargement de la page',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
                this.disableNavigation = false;
            }
        },

        toggleOrder() {
            this.by = this.by === 'asc' ? 'desc' : 'asc';
            this.fetchContacts(true);
        }
    },

    watch: {
        $route(to) {
            if (!this.disableNavigation) {
                if (!this.embeded) {
                    this.parseQuery(to.query);
                }
                this.fetchContacts(false);
            }
        },

        displayAdvanceSearch() {
            if (this.displayAdvanceSearch && !this.advanceSearchDataFetched) {
                this.advanceSearchDataFetched = true;
                this.$refs.programAutocomplete.fetchPrograms({ limit: 1000 });
            }
        }
    },

    created() {
        if (this.embeded) {
            this.programsFilter = this.programs;
            this.headers = this.headers.filter((h) => h.value !== 'programs');
        } else {
            this.parseQuery(this.$route.query);
        }
        this.fetchContacts(false, false);
    }
};
</script>

<style lang="scss" scoped>
.pagination::v-deep .v-pagination {
    .v-pagination__navigation:last-child {
        margin-right: 0;
    }
}

.sort-pagination::v-deep {
    .v-input__control {
        max-width: 300px;
    }

    .v-input__append-outer {
        margin: 0 !important;
    }
}
</style>
